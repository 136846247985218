<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="1800px"
    max-height="1000px"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >Device Under Test File Upload</span
        >
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-sheet :color="dragIn ? 'alert' : 'primary'" dark outlined shaped>
              <div
                v-if="!testpoint_file"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
                class="pa-10"
              >
                <input
                  type="file"
                  style="display: none"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  @change="onConvert"
                  ref="file"
                  accept=".csv"
                  :key="`file_input${inputCounter}`"
                  data-cy="rfq-dut-report-file-input"
                />
                <label for="assetsFieldHandle" class="fieldLink">
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <v-icon x-large>
                        mdi-cloud-upload
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h1 class="mt-2 pa-1 font-weight-bold">
                        Upload a DUT (Device Under Test) File that can be parsed
                        for test features.
                      </h1>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex justify-center">
                      <h2 class="mt-5 mb-10 font-weight-light">
                        Test points, mounting holes, the board outline, pressure
                        pin coordinates etc...
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="4" class="d-flex justify-center">
                      <span v-html="dutReport"></span>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-center">
                      <span v-html="altiumReport"></span>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-center">
                      <span v-html="experimentalFiles"></span>
                    </v-col>
                  </v-row>
                </label>
              </div>
              <div v-else class="pa-10">
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-icon x-large>
                      mdi-cloud-upload
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <h1 class="mt-2 pa-1 font-weight-bold">
                      {{
                        testpoint_file.name
                          ? testpoint_file.name
                          : "Currently saved Test Point file"
                      }}
                    </h1>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <div class="pa-1">
                      Saving the Test Point list FixturFab will use to design
                      your fixture.
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex justify-center">
                    <v-btn
                      color="secondary"
                      @click="initializeLists"
                      data-cy="rfq-dut-report-remove-file"
                      >Remove file</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <div
          fluid
          v-if="
            testpoint_file &&
              !isLoading &&
              (parsedTestPoints.length > 0 ||
                parsedGuidePins.length > 0 ||
                parsedPressurePins.length > 0)
          "
        >
          <v-alert dark dense color="accent" class="my-2 text-center">
            Update the following values if needed.
          </v-alert>
          <div class="mb-5">
            <v-form ref="form">
              <v-card
                class="d-flex flex-row justify-space-between my-10"
                :color="
                  $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'
                "
                flat
                tile
              >
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">Units</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-autocomplete
                      :items="dutUnits"
                      name="dutUnits"
                      item-text="description"
                      item-value="pk"
                      v-model="dutModalUnits"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      :rules="rules.requireCheck"
                      @change="
                        recomputeUOM({
                          unitSelected: dutModalUnits,
                          isSaving: false,
                        })
                      "
                      data-cy="rfq-dut-report-dut-units"
                    >
                    </v-autocomplete
                  ></span>
                </v-sheet>
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">X Origin</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-text-field
                      name="dutXOrigin"
                      type="number"
                      v-model="dutXOrigin"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      step="0.0001"
                      @change="computeValue('dutXOrigin')"
                      :onInput="onInputString()"
                      :rules="hybridRules(dutXOrigin)"
                      data-cy="rfq-dut-report-dut-xorigin"
                    >
                    </v-text-field
                  ></span>
                </v-sheet>
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">Y Origin</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-text-field
                      name="dutYOrigin"
                      type="number"
                      v-model="dutYOrigin"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      step="0.0001"
                      @change="computeValue('dutYOrigin')"
                      :onInput="onInputString()"
                      :rules="hybridRules(dutYOrigin)"
                      data-cy="rfq-dut-report-dut-yorigin"
                    >
                    </v-text-field
                  ></span>
                </v-sheet>
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">DUT Length</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-text-field
                      name="dutLength"
                      type="number"
                      v-model="dutLength"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      step="0.0001"
                      @change="computeValue('dutLength')"
                      :onInput="onInputString()"
                      :rules="rules.requireCheck"
                      :class="dutLength > 0 ? '' : 'text-alert'"
                      data-cy="rfq-dut-report-dut-length"
                    >
                    </v-text-field
                  ></span>
                </v-sheet>
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">DUT Width</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-text-field
                      name="dutWidth"
                      type="number"
                      v-model="dutWidth"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      step="0.0001"
                      @change="computeValue('dutWidth')"
                      :onInput="onInputString()"
                      :rules="rules.requireCheck"
                      :class="dutWidth > 0 ? '' : 'text-alert'"
                      data-cy="rfq-dut-report-dut-width"
                    >
                    </v-text-field
                  ></span>
                </v-sheet>
                <v-sheet outlined tile width="300" class="pa-4">
                  <span class="subtitle-1 ml-3">DUT Height</span>
                  <v-divider class="my-4" />
                  <span
                    ><v-text-field
                      name="dutHeight"
                      type="number"
                      v-model="dutHeight"
                      flat
                      single-line
                      hide-details
                      dense
                      class="subtitle-1"
                      step="0.0001"
                      @change="computeValue('dutHeight')"
                      :onInput="onInputString()"
                      :rules="rules.requireCheck"
                      :class="dutHeight > 0 ? '' : 'text-alert'"
                      data-cy="rfq-dut-report-dut-height"
                    >
                    </v-text-field
                  ></span>
                </v-sheet>
              </v-card>
            </v-form>
            <transition name="slide">
              <div
                v-if="
                  !dutModalUnits ||
                    dutXOrigin.length == 0 ||
                    dutYOrigin.length == 0 ||
                    !dutLength ||
                    !dutWidth ||
                    !dutHeight ||
                    dutLength == 0 ||
                    dutWidth == 0 ||
                    dutHeight == 0
                "
              >
                <v-row>
                  <v-col cols="12">
                    <span class="font-weight-bold subtitle-1 primary--text ml-4"
                      >Missing some optional columns. You can add values above
                      or add the columns to your CSV and re-upload it. See our
                      DUT Report Template for an example.</span
                    >
                  </v-col>
                </v-row>
                <MissingHeaderNotes
                  title="Enter the required data for this DUT above."
                  subtitle_1="• Units - We recommend mm"
                  subtitle_2="• X-Origin - We recommend 0"
                  subtitle_3="• Y-Origin - We recommend 0"
                  subtitle_4="• DUT Length"
                  subtitle_5="• DUT Width"
                  subtitle_6="• DUT Height"
                  :validSub1="!dutModalUnits"
                  :validSub2="dutXOrigin.length == 0"
                  :validSub3="dutYOrigin.length == 0"
                  :validSub4="!dutLength || dutLength == 0"
                  :validSub5="!dutWidth || dutWidth == 0"
                  :validSub6="!dutHeight || dutHeight == 0"
                  color="error"
                />
              </div>
            </transition>
          </div>
          <v-alert dark dense color="accent" class="my-2 text-center">
            Individual Test Point, Guide Pin, and Pressure Pin values must be
            edited in the file and re-uploaded.
          </v-alert>
          <v-divider />
          <v-card flat>
            <v-toolbar flat>
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab>
                  Test Points
                </v-tab>
                <v-tab>
                  Guide Pins
                </v-tab>
                <v-tab>
                  Pressure Pins
                </v-tab>
              </v-tabs>
            </v-toolbar>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <DataTableTestPoints
                  :arrayList="mappedTestPoints"
                  :isLoading="isLoading"
                />
              </v-tab-item>
              <v-tab-item>
                <DataTableGuidePins
                  :arrayList="parsedGuidePins"
                  :isLoading="isLoading"
                />
              </v-tab-item>
              <v-tab-item>
                <DataTableGuidePins
                  :arrayList="parsedPressurePins"
                  :isLoading="isLoading"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <v-sheet v-if="isAdmin" outlined class="pa-5">
            <GerberLayers
              key="2"
              v-if="zipGerberFiles.length > 0 && !isLoading"
              ref="gerber_layers_dut"
              :topCopperContainer="topCopperContainer"
              :topSoldermaskContainer="topSoldermaskContainer"
              :topSilkscreenContainer="topSilkscreenContainer"
              :bottomCopperContainer="bottomCopperContainer"
              :bottomSoldermaskContainer="bottomSoldermaskContainer"
              :bottomSilkscreenContainer="bottomSilkscreenContainer"
              :allMechanicalContainer="allMechanicalContainer"
              :allDrillContainer="allDrillContainer"
              :testPoints="parsedTestPoints"
            />
          </v-sheet>
        </div>
        <v-divider />
        <v-card-actions class="ma-2">
          <v-row v-if="testpoint_file">
            <v-col class="d-flex justify-center">
              <v-btn color="warning" class="mx-auto" @click="onCancel"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="mx-auto"
                @click="
                  showBottomDialog({
                    nextAction: `saveDut`,
                    message: `Are you sure you want to upload this new DUT Report? It will do the following:`,
                    subtitle_1: `Delete current Test Points, Guide Pins, and Receptacles`,
                    subtitle_2: `Create new Test Points, Guide Pins, and Receptacles from this new data.`,
                  })
                "
                data-cy="rfq-dut-report-save-button"
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="d-flex justify-center">
              <v-btn
                color="warning"
                @click="onClose"
                data-cy="rfq-dut-report-close-button"
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
      <BaseMmToMilsDialog
        :dialog="mmToMilsDialog"
        :message="confirmMessage"
        :action="confirmAction"
        @saveUOM="assignUOM"
        @donothing="stopMilsDialog"
      />
      <BaseBottomDialog
        :dialog="bottomDialog"
        :message="confirmMessage"
        :action="confirmAction"
        :subtitle_1="subtitle_1"
        :subtitle_2="subtitle_2"
        @saveDut="onSave"
        @donothing="stopDialog"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import GerberLayer from "@/mixins/GerberLayer";
export default {
  name: "FileParserModal",
  mixins: [BottomDialog, GerberLayer],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    parsedJsonBlob: {
      type: Object,
      default: null,
    },
    parsedTestPoints: {
      type: Array,
      default: () => [],
    },
    parsedGuidePins: {
      type: Array,
      default: null,
    },
    parsedPressurePins: {
      type: Array,
      default: null,
    },
    parsedHoles: {
      type: Array,
      default: null,
    },
    parsedDutData: {
      type: Object,
      default: null,
    },
    parsedMissingHeaders: {
      type: Array,
      default: null,
    },
    testpointLayers: {
      type: Array,
      default: null,
    },
    testpointTypes: {
      type: Array,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    pcbStackup: {
      type: Object,
      default: null,
    },
    savedDut: {
      type: String,
      default: null,
    },
    dutReport: {
      type: String,
      default: null,
    },
    altiumReport: {
      type: String,
      default: null,
    },
    experimentalFiles: {
      type: String,
      default: null,
    },
    dutUnits: {
      type: Array,
      default: null,
    },
    dutDetails: {
      type: Object,
      default: null,
    },
    calledFromConfig: {
      type: Boolean,
      default: false,
    },
    savedGerbers: {
      type: Array,
      default: null,
    },
  },
  components: {
    GerberLayers: () =>
      import("@/modules/dutconfigs/components/GerberLayers.vue"),
    DataTableTestPoints: () => import("./DataTableTestPoints.vue"),
    DataTableGuidePins: () => import("./DataTableGuidePin.vue"),
    MissingHeaderNotes: () => import("./MissingHeaderNotes.vue"),
  },
  data() {
    return {
      tab: 0,
      testpoint_file: null,
      dragIn: false,
      isLoading: false,
      config2d: {
        stackupScale: 1000,
        inchToMm: 25.4,
        isUnitMm: null,
        pressureTip: null,
        pcbHeight: null,
        pcbWidth: null,
        topSvgWidth: null,
        topLayerExist: null,
        bottomLayerExist: null,
        viewBox: [0, 0, 0, 0],
      },
      xMultiplier: 1,
      yMultiplier: 1,
      pcbRenderKey: 0,
      dutModalUnits: null,
      dutXOrigin: 0.0000,
      dutYOrigin: 0.0000,
      dutLength: 0.0000,
      dutWidth: 0.0000,
      dutHeight: 0.0000,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
      MILS_TO_MM_CONVERSION: 0.0254,
      mmToMilsDialog: false,
      inputCounter: 0,
      subtitle_1: null,
      subtitle_2: null,
    };
  },
  computed: {
    mappedTestPoints() {
      return this.parsedTestPoints
        ? this.parsedTestPoints.map((testpoint, index) => {
            const layerFound = this.testpointLayers.find(
              (tplayer) => tplayer.pk == testpoint.layer
            );
            const typeFound = this.testpointTypes.find(
              (tptype) => tptype.pk == testpoint.type
            );
            return {
              ...testpoint,
              layer_name: layerFound ? layerFound["description"] : null,
              type_name: typeFound ? typeFound["description"] : null,
              receptacle: testpoint.requested_receptacle_type
                ? testpoint.requested_receptacle_type
                : this.parsedDutData?.receptacle_type,
              probe: testpoint.requested_probe_type
                ? testpoint.requested_probe_type
                : this.parsedDutData?.probe_tip_type,
              index_number: index + 1,
            };
          })
        : [];
    },
    isUnitMissing() {
      return this.parsedMissingHeaders &&
        Object.keys(this.parsedMissingHeaders).length > 0
        ? this.parsedMissingHeaders?.includes("units")
        : false;
    },
    zipGerberFiles() {
      return this.$store.getters["dutconfigs/zipGerberFiles"];
    },
  },
  methods: {
    hybridRules(value = "") {
      const rules = [];
      if (value.length == 0) {
        const anotherRule = () => "Input is required";
        rules.push(anotherRule);
      }
      return rules;
    },
    dragover(event) {
      event.preventDefault();
      this.dragIn = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.dragIn = false;
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.testpoint_file = this.$refs.file.files;
      this.onConvert();
      this.dragIn = false;
    },
    initializeLists() {
      this.dutModalUnits = null;
      this.dutXOrigin = this.dutYOrigin = 0;
      this.testpoint_file = this.dutLength = this.dutWidth = this.dutHeight = null;
      this.$emit("initCachedParsedData");
    },
    onClose() {
      this.$emit("closeModal");
      this.initializeLists();
    },
    onCancel() {
      if (this.isLoading) return;
      this.$emit("closeModal");
      this.initializeLists();
    },
    async onConvert() {
      this.testpoint_file = this.$refs.file.files[0];
      this.isLoading = true;
      this.$emit("parseTestPoint", this.testpoint_file);
      this.isLoading = false;
    },
    onSave() {
      if (this.dutModalUnits === 2) {
        this.dutModalUnits = 1;
        this.recomputeUOM({ unitSelected: this.dutModalUnits, isSaving: true });
      }
      const dut_settings = {
        units: this.dutModalUnits,
        x_origin: this.dutXOrigin,
        y_origin: this.dutYOrigin,
        dut_length: this.dutLength,
        dut_width: this.dutWidth,
        dut_height: this.dutHeight,
      };

      const actionPayload = {
        testpoint_file: this.testpoint_file["name"]
          ? this.testpoint_file
          : null,
        dut_settings,
        parsed_json_blob: { ...this.parsedJsonBlob },
      };
      this.bottomDialog && this.stopDialog();
      this.$emit("saveParsedTestPoints", actionPayload);
      this.onClose();
    },
    initConfig2d() {
      this.config2d.pressureTip =
        this.pcbStackup.top.units == "mm"
          ? this.pcbStackup.top.viewBox[2] / this.pcbStackup.top.width
          : this.pcbStackup.top.viewBox[2] /
            this.pcbStackup.top.width /
            this.config2d.inchToMm;
      this.config2d.topSvgWidth = this.pcbStackup.top.viewBox[2];
      this.config2d.isUnitMm = this.pcbStackup.top.units == "mm" ? true : false;
      this.config2d.pcbHeight = this.pcbStackup.top.height;
      this.config2d.pcbWidth = this.pcbStackup.top.width;
      this.config2d.topLayerExist =
        this.pcbStackup.top.defs.length > 2 ? true : false;
      this.config2d.bottomLayerExist =
        this.pcbStackup.bottom.defs.length > 2 ? true : false;
      this.config2d.viewBox = this.pcbStackup.top.viewBox;

      if (Object.keys(this.parsedDutData).length > 0) {
        this.dutModalUnits = this.parsedDutData.units
          ? this.dutUnits.find((element) =>
              element.description.includes(this.parsedDutData.units)
            )?.pk
          : null;
        this.dutXOrigin = this.parsedDutData.x_origin
          ? ((this.parsedDutData.x_origin / 100) * 100).toFixed(4)
          : 0;
        this.dutYOrigin = this.parsedDutData?.y_origin
          ? ((this.parsedDutData?.y_origin / 100) * 100).toFixed(4)
          : 0;
        this.dutLength = this.parsedDutData.dut_length
          ? ((this.parsedDutData.dut_length / 100) * 100).toFixed(4)
          : null;
        this.dutWidth = this.parsedDutData.dut_width
          ? ((this.parsedDutData.dut_width / 100) * 100).toFixed(4)
          : null;
        this.dutHeight = this.parsedDutData.dut_height
          ? ((this.parsedDutData.dut_height / 100) * 100).toFixed(4)
          : null;
        if (
          this.parsedDutData.units === "mils" ||
          this.parsedDutData.units === "mil"
        ) {
          this.$store.commit(
            "ui/SNACK_BAR",
            "You specified mils, but FixturFab works in mm. Displaying float values in mils, but each will be converted to mm upon saving."
          );
        }
      }
      if (!this.dutModalUnits || this.isUnitMissing) {
        this.confirmMessage =
          "No units were specified. Is this file in mm or mils?";
        this.confirmAction = "saveUOM";
        this.mmToMilsDialog = true;
      }
    },
    onUpdateViewBox(payload) {
      if (payload.type == "x") {
        this.xMultiplier = payload.value;
      } else {
        this.yMultiplier = payload.value;
      }
    },
    initTestPointFile() {
      this.testpoint_file = null;
      this.inputCounter++;
    },
    recomputeUOM({ unitSelected, isSaving }) {
      this.$store.commit("quotes/RECOMPUTE_UOM", unitSelected);
      this.dutLength = (
        this.dutLength *
        (unitSelected === 1
          ? this.MILS_TO_MM_CONVERSION
          : 1 / this.MILS_TO_MM_CONVERSION)
      ).toFixed(2);
      this.dutWidth = (
        this.dutWidth *
        (unitSelected === 1
          ? this.MILS_TO_MM_CONVERSION
          : 1 / this.MILS_TO_MM_CONVERSION)
      ).toFixed(2);
      this.dutHeight = (
        this.dutHeight *
        (unitSelected === 1
          ? this.MILS_TO_MM_CONVERSION
          : 1 / this.MILS_TO_MM_CONVERSION)
      ).toFixed(2);
      if (unitSelected === 2 && !isSaving) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "You specified mils, but FixturFab works in mm. Displaying float values in mils, but each will be converted to mm upon saving."
        );
      }
    },
    stopMilsDialog() {
      this.mmToMilsDialog = false;
    },
    assignUOM({ pk }) {
      this.dutModalUnits = pk;
      if (pk === 2) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "You specified mils, but FixturFab works in mm. Displaying float values in mils, but each will be converted to mm upon saving."
        );
      }
      this.stopMilsDialog();
    },
    showBottomDialog({ nextAction, message, subtitle_1, subtitle_2 }) {
      if (!this.testpoint_file) return;
      if (!this.$refs.form.validate()) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        this.$vuetify.goTo(0);
        return;
      }
      if (this.calledFromConfig) {
        this.confirmAction = nextAction;
        this.confirmMessage = message;
        this.subtitle_1 = subtitle_1;
        this.subtitle_2 = subtitle_2;
        this.bottomDialog = true;
      } else {
        this.onSave();
      }
    },
    initLayers() {
      this.$refs["gerber_layers_dut"] &&
        this.$refs["gerber_layers_dut"].initializeViewBox();
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;";
    },
    computeValue(fieldName) {
      this[fieldName] = (this[fieldName] * 1).toFixed(4) ;
    },
  },
  async mounted() {
    this.dragIn = false;
    this.isLoading = false;
    console.log(this.savedDut);
    if (this.savedDut) {
      this.testpoint_file = this.savedDut.split("?")[0].split("/").pop();
      console.log("MOUNTED", this.testpoint_file, this.savedDut);
    }
    await this.loadSavedGerbers();
  },
};
</script>
<style scoped>
.text-alert >>> .v-text-field__slot input {
  color: red;
}
</style>
